/* eslint-disable camelcase */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as RichText from '@prismicio/richtext';
import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react';
import ServiceModal from './ServiceModal';
import Loading from '../Common/Loading';

const Home = ({ entry }) => {
  const {
    main_title,
    sub_title,
    background,
    intro,
    call_to_action,
    call_to_action_url,
    appeal,
    appeal_call_to_action,
    appeal_url,
  } = entry.data;
  const [servicesEntry] = usePrismicDocumentByUID('service', 'services');
  if (servicesEntry === undefined) return (<Loading />);
  return (
    <div className="home">
      <div className="banner" style={{ backgroundImage: `url(${background.url}&width=1400)` }}>
        <Container>
          <Row>
            <Col lg={6}>
              <h2>{RichText.asText(sub_title)}</h2>
              <h1>{RichText.asText(main_title)}</h1>
              <div className="intro">
                <PrismicRichText field={intro} />
              </div>
              <div className="call-to-action">
                <a href={call_to_action_url}>
                  <PrismicRichText field={call_to_action} />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="services">
        <h2>Our Services</h2>
        <Container>
          <Row className="service-row">
            <Col lg={9}>
              <Row>
                {servicesEntry.data.services.map((service) => (
                  <Col md={3} xs={6} key={service.service}>
                    <ServiceModal service={service} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="call-to-action">
          <Link to={servicesEntry.data.call_to_action_url}>
            {servicesEntry.data.call_to_action}
          </Link>
        </div>
      </div>
      <div className="tag-line">
        <PrismicRichText field={appeal} />
        <div className="call-to-action">
          <a href={appeal_url}>{RichText.asText(appeal_call_to_action)}</a>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  entry: PropTypes.shape({
    data: PropTypes.shape({
      main_title: PropTypes.arrayOf(PropTypes.object),
      sub_title: PropTypes.arrayOf(PropTypes.object),
      intro: PropTypes.arrayOf(PropTypes.object),
      call_to_action: PropTypes.arrayOf(PropTypes.object),
      call_to_action_url: PropTypes.string,
      appeal: PropTypes.arrayOf(PropTypes.object),
      appeal_call_to_action: PropTypes.arrayOf(PropTypes.object),
      appeal_url: PropTypes.string,
      background: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default Home;
