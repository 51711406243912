import React from 'react';
import { Route, Switch } from 'react-router';
import { Helmet } from 'react-helmet';
import { usePrismicDocumentByUID } from '@prismicio/react';
import * as RichText from '@prismicio/richtext';

import './scss/App.scss';
import Footer from './Common/Footer';
import GlobalNav from './Common/GlobalNav';
import Home from './Home/Home';
import Loading from './Common/Loading';
import Page from './Page/Page';

const App = () => {
  const [entry] = usePrismicDocumentByUID('home', 'home');
  if (entry === undefined) return (<Loading />);
  // console.log(entry.data);
  const socialTitle = `${RichText.asText(entry.data.sub_title)} ${RichText.asText(entry.data.main_title)}`;
  const socialDescription = RichText.asText(entry.data.meta_description);
  const socialImage = entry.data.meta_image.url || entry.data.background.url;
  return (
    <div className="App">
      <Helmet>
        <title>{socialTitle}</title>
        <meta name="description" content={socialDescription} />

        <meta property="og:title" content={socialTitle} />
        <meta property="og:image" content={socialImage} />

        <meta name="twitter:title" content={socialTitle} />
        <meta name="twitter:image" content={socialImage} />
      </Helmet>
      <GlobalNav entry={entry} />
      <Switch>
        <Route path="/page/:uid/page/:page" exact><Page homeEntry={entry} /></Route>
        <Route path="/page/:uid/:subuid" exact><Page homeEntry={entry} /></Route>
        <Route path="/page/:uid" exact><Page homeEntry={entry} /></Route>
        <Route path="/"><Home entry={entry} /></Route>
      </Switch>
      <Footer entry={entry} />
    </div>
  );
};

export default App;
