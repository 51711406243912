import React, { useEffect, useState } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { PrismicRichText } from '@prismicio/react';
import * as RichText from '@prismicio/richtext';

import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as prismic from '@prismicio/client';
import Loading from '../Common/Loading';
import { PRISMIC_REPO_NAME } from '../Constant';

const SliceNews = () => {
  const { subuid, page } = useParams();
  const client = prismic.createClient(PRISMIC_REPO_NAME);

  const [newsResult, setNewsResult] = useState({
    results: [],
  });
  useEffect(() => {
    (async () => {
      const predicates = [];
      if (subuid === undefined) {
        predicates.push(prismic.predicate.at('document.type', 'new'));
      } else {
        predicates.push(prismic.predicate.at('my.new.uid', subuid));
      }
      const results = await client.get({
        predicates,
        orderings: { field: 'my.new.postedon', direction: 'desc' },
        page: page || 1,
        // pageSize: 1,
      });
      setNewsResult(results);
    })();
  }, [page, subuid]);

  const social = {
    title: '',
    description: '',
    image: '',
  };

  if (subuid !== undefined) {
    if (newsResult === undefined || newsResult.results[0] === undefined) return (<Loading />);
    social.title = RichText.asText(newsResult.results[0].data.title);
    social.description = RichText.asText(newsResult.results[0].data.teaser);
  }

  return (
    <div className="slice-news">
      {subuid !== undefined ? (
        <Helmet>
          <title>{social.title}</title>
          <meta name="description" content={social.description} />

          <meta property="og:title" content={social.title} />
          <meta property="og:image" content={social.image} />

          <meta name="twitter:title" content={social.title} />
          <meta name="twitter:image" content={social.image} />
        </Helmet>
      ) : null}
      <Container>
        <Row className="d-flex justify-content-center">
          <Col lg={subuid ? 10 : 8}>
            {newsResult.results.map((newsRow) => (
              <div className="news-row" key={newsRow.id}>
                {subuid !== undefined ? (
                  <div>
                    <h1>{RichText.asText(newsRow.data.title)}</h1>
                    <PrismicRichText field={newsRow.data.body} />
                  </div>
                ) : (
                  <div>
                    <h2><Link to={`/page/news-updates/${newsRow.uid}`}>{RichText.asText(newsRow.data.title)}</Link></h2>
                    <PrismicRichText field={newsRow.data.teaser} />
                    <Link to={`/page/news-updates/${newsRow.uid}`}>Read More</Link>
                  </div>
                )}
              </div>
            ))}
            {newsResult && newsResult.total_results_size > newsResult.results_per_page ? (
              <div>
                {newsResult.page !== 1 ? (
                  <Link to={`/page/news-updates/page/${newsResult.page - 1}`}>Prev </Link>
                ) : null}
                Page
                {' '}
                {newsResult.page}
                {' '}
                of
                {' '}
                {newsResult.total_pages}
                {newsResult.page < newsResult.total_pages ? (
                  <Link to={`/page/news-updates/page/${newsResult.page + 1}`}> Next</Link>
                ) : null}
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
SliceNews.propTypes = {
  slice: PropTypes.shape({}).isRequired,
};

export default SliceNews;
