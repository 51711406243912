import React from 'react';
import {
  Button,
  Col, Container, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';

const SliceContact = ({ slice }) => (
  <div className="slice-contact">
    <Container>
      <Row className="d-flex justify-content-center">
        <Col lg={6}>
          <div className="message">
            <PrismicRichText field={slice.primary.message} />
          </div>
          <form>
            <label>Name</label>
            <input type="text" name="name" />
            <label>Email</label>
            <input type="email" name="email" />
            <label>Phone</label>
            <input type="text" name="phone" />
            <label>Message</label>
            <textarea name="message" />
            <div className="submit-button">
              <Button type="submit" variant="primary">Send</Button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  </div>
);
SliceContact.propTypes = {
  slice: PropTypes.shape({}).isRequired,
};

export default SliceContact;
