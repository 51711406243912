import React from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { PrismicRichText } from '@prismicio/react';
import ContactForm from '../Common/ContactFrom';

const SliceLocations = ({ slice, homeEntry }) => (
  <div className="slice-locations">
    <Container>
      <Row className="d-flex justify-content-center">
        <Col md={6} className="map">
          <Row>
            <Col lg={6}>
              <PrismicRichText field={homeEntry.data.address_1} />
            </Col>
            <Col lg={6}>
              <PrismicRichText field={homeEntry.data.address_2} />
            </Col>
          </Row>
          <div className="embed" dangerouslySetInnerHTML={{ __html: slice.primary.map.html }} />
        </Col>
        <Col md={6}>
          <div className="contact-col">
            <h3>Send Us A Message</h3>
            <ContactForm contactDisclaimer={homeEntry.data.legal_contact} />
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);
SliceLocations.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      map: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
  }).isRequired,
  homeEntry: PropTypes.shape({
    data: PropTypes.shape({
      address_1: PropTypes.arrayOf(PropTypes.object),
      address_2: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

export default SliceLocations;
