import React, { useState } from 'react';
import {
  Col, Container, Modal, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { PrismicRichText, usePrismicDocumentsByIDs } from '@prismicio/react';
import * as RichText from '@prismicio/richtext';
import Loading from '../Common/Loading';

const SlicePeople = ({ slice }) => {
  const { subuid } = useParams();

  const ids = slice.items.map((people) => people.people.id);
  const [docs] = usePrismicDocumentsByIDs(ids);
  if (docs === undefined || ids.length === 0) return (<Loading />);
  const entries = docs.results;

  let currentEntry = {
    data: {
      headshot: {},
      name: [],
      title: '',
      bio: [],
    },
  };
  entries.forEach((entry) => {
    if (entry.uid === subuid) {
      currentEntry = entry;
    }
  });
  const socialTitle = `${RichText.asText(currentEntry.data.name)} - ${currentEntry.data.title}`;
  const socialDescription = RichText.asText(currentEntry.data.bio);
  const socialImage = currentEntry.data.headshot.url;

  return (
    <div className="slice-people">
      {subuid !== undefined ? (
        <Helmet>
          <title>{socialTitle}</title>
          <meta name="description" content={socialDescription} />

          <meta property="og:title" content={socialTitle} />
          <meta property="og:image" content={socialImage} />

          <meta name="twitter:title" content={socialTitle} />
          <meta name="twitter:image" content={socialImage} />
        </Helmet>
      ) : null}
      <Container>
        <Row className="d-felx justify-content-center">
          <Col lg={8}>
            <Row>
              {entries.map((entry) => (
                <Col lg="6" key={entry.id}><EntryModal entry={entry} /></Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
SlicePeople.propTypes = {
  slice: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

const EntryModal = ({ entry }) => {
  const { subuid } = useParams();
  const [show, setShow] = useState(subuid === entry.uid);
  // console.log(entry);
  const toggleShow = () => {
    setShow(!show);
  };
  return (
    <div className="people-entry">
      <button type="button" onClick={toggleShow} className="as-text">
        <img src={`${entry.data.headshot.url}&width=250&height=250&fit=crop`} className="headshot" alt="headshot" />
        <h3>{RichText.asText(entry.data.name)}</h3>
        <div>{entry.data.title}</div>
      </button>
      <Modal show={show} onHide={toggleShow} size="md" centered>
        <div className="people-modal">
          <button className="as-text close-button" type="button" onClick={toggleShow}><i className="fas fa-times" /></button>
          <div>
            <img src={`${entry.data.headshot.url}&width=250&height=250&fit=crop`} className="modal-headshot" alt="headshot" />
            <h3>{RichText.asText(entry.data.name)}</h3>
            <div>{entry.data.title}</div>
            <div className="email"><a href={`mailto:${entry.data.email}`}>{entry.data.email}</a></div>
            <div className="area">{entry.data.area}</div>
            <PrismicRichText field={entry.data.bio} />
            <div className="quote">{entry.data.quote}</div>
            <div className="perma"><Link to={`/page/attorneys/${entry.uid}`}><i className="fas fa-link" /></Link></div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
EntryModal.propTypes = {
  entry: PropTypes.shape({
    uid: PropTypes.string,
    data: PropTypes.shape({
      headshot: PropTypes.shape({
        url: PropTypes.string,
      }),
      title: PropTypes.string,
      area: PropTypes.string,
      quote: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.arrayOf(PropTypes.object),
      bio: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

export default SlicePeople;
