import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import * as RichText from '@prismicio/richtext';

import { usePrismicDocumentByUID } from '@prismicio/react';
import Loading from '../Common/Loading';
import SliceServices from './SliceServices';
import SlicePeople from './SlicePeople';
import SliceLocations from './SliceLoations';
import SliceContact from './SliceContact';
import SliceNews from './SliceNews';

const Page = ({ homeEntry }) => {
  const { uid } = useParams();
  const [entry] = usePrismicDocumentByUID('page', uid);
  if (entry === undefined || entry.id === undefined) return (<Loading />);
  const {
    banner,
    title,
    sub_title,
    body,
    meta_description,
    meta_image,
  } = entry.data;
  const renderSlice = (slice) => {
    switch (slice.slice_type) {
      case 'contact_us':
        return (<SliceContact slice={slice} />);
      case 'office_locations':
        return (<SliceLocations slice={slice} homeEntry={homeEntry} />);
      case 'services':
        return (<SliceServices slice={slice} />);
      case 'people':
        return (<SlicePeople slice={slice} />);
      case 'news':
        return (<SliceNews slice={slice} />);
      default:
        return (
          <div key={slice.slice_type}>
            Unknown slice:
            {slice.slice_type}
          </div>
        );
    }
  };
  const socialTitle = `${RichText.asText(title)} - ${RichText.asText(sub_title)}`;
  const socialDescription = RichText.asText(meta_description);
  const socialImage = meta_image ? meta_image.url : `${banner.url}&1400`;
  return (
    <div className="page">
      <Helmet>
        <title>{socialTitle}</title>
        <meta name="description" content={socialDescription} />

        <meta property="og:title" content={socialTitle} />
        <meta name="twitter:title" content={socialTitle} />

        <meta property="og:image" content={socialImage} />
        <meta name="twitter:image" content={socialImage} />
      </Helmet>
      <div
        className="banner"
        style={
          {
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.6)),url(${banner.url}&1400)`,
          }
        }
      >
        <Container>
          <Row>
            <Col lg={6}>
              <h1>{RichText.asText(title)}</h1>
              <h2>{RichText.asText(sub_title)}</h2>
            </Col>
          </Row>
        </Container>
      </div>
      {body.map((slice) => (
        <div key={slice.slice_type}>{renderSlice(slice)}</div>
      ))}
    </div>
  );
};

Page.propTypes = {
  homeEntry: PropTypes.shape({
    data: PropTypes.shape({
      address_1: PropTypes.arrayOf(PropTypes.object),
      address_2: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

export default Page;
