import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Container,
  Modal, Row,
} from 'react-bootstrap';

import { PrismicRichText } from '@prismicio/react';
import Loading from '../Common/Loading';

const ServiceModal = ({ service }) => {
  const [show, setShow] = useState(false);
  // console.log(service);
  const toggleShow = () => {
    setShow(!show);
  };
  if (service === undefined) return (<Loading />);
  return (
    <div className="service-col">
      <button className="as-text" type="button" onClick={toggleShow}>
        <div><img src={service.icon.url} alt="icon" /></div>
        <div>{service.service}</div>
      </button>
      <Modal show={show} onHide={toggleShow} size="lg" centered>
        <div className="service-modal">
          <Container>
            <Row>
              <Col lg={3} className="service-icon">
                <img src={service.icon_k.url} alt="icon" />
              </Col>
              <Col lg={9} className="service-description">
                <button className="as-text close-button" type="button" onClick={toggleShow}><i className="fas fa-times" /></button>
                <h2>{service.service}</h2>
                <PrismicRichText field={service.description} />
              </Col>
            </Row>
          </Container>
        </div>
      </Modal>
    </div>
  );
};
ServiceModal.propTypes = {
  service: PropTypes.shape({
    service: PropTypes.string,
    description: PropTypes.arrayOf(PropTypes.object),
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    icon: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};
ServiceModal.defaultProps = {
  service: {
    service: '',
    description: [],
    image: { url: '' },
    icon: { url: '' },
  },
};
export default ServiceModal;
