import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PrismicRichText } from '@prismicio/react';

const Footer = ({ entry }) => {
  const {
    legal_1,
    legal_2,
    legal_3,
    social_icons,
    address_1,
    address_2,
    footer_logo,
  } = entry.data;
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col md={3} className="footer-col">
            <Link to="/"><img src={footer_logo.url} alt="logo" className="logo" /></Link>
          </Col>
          <Col md={3} className="footer-col">
            <PrismicRichText field={address_1} />
          </Col>
          <Col md={3} className="footer-col">
            <PrismicRichText field={address_2} />
          </Col>
          <Col md={3} className="footer-col">
            <h3>Follow us on:</h3>
            <ul className="social">
              {social_icons.map((row) => (
                <li key={row.icon_url}>
                  <a
                    href={row.icon_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className={row.icon_class} />
                  </a>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="legal">
              <Row>
                <Col md={4}>
                  <PrismicRichText field={legal_1} />
                </Col>
                <Col md={4}>
                  <PrismicRichText field={legal_2} />
                </Col>
                <Col md={4}>
                  <PrismicRichText field={legal_3} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
Footer.propTypes = {
  entry: PropTypes.shape({
    data: PropTypes.shape({
      social_icons: PropTypes.arrayOf(PropTypes.object),
      address_1: PropTypes.arrayOf(PropTypes.object),
      address_2: PropTypes.arrayOf(PropTypes.object),
      legal_1: PropTypes.arrayOf(PropTypes.object),
      legal_2: PropTypes.arrayOf(PropTypes.object),
      legal_3: PropTypes.arrayOf(PropTypes.object),
      footer_logo: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default Footer;
