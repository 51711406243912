/* eslint-disable jsx-a11y/label-has-associated-control */
import { PrismicRichText } from '@prismicio/react';
import React from 'react';
import { Button } from 'react-bootstrap';

const ContactForm = ({ contactDisclaimer }) => (
  <div className="contact-form">
    <form action="https://usebasin.com/f/82268c767ec8" method="POST">
      <label>Name</label>
      <input type="text" name="name" />
      <label>Email</label>
      <input type="email" name="email" />
      <label>Phone</label>
      <input type="text" name="phone" />
      <label>Message</label>
      <input type="hidden" name="address" />
      <textarea name="message" />
      <div className="submit-button">
        <Button type="submit" variant="primary">Send</Button>
      </div>
    </form>
    <div className="contact-disclaimer">
      <PrismicRichText field={contactDisclaimer} />
    </div>
  </div>
);

ContactForm.defaultProps = {
  contactDisclaimer: [],
};

export default ContactForm;
