/* eslint-disable camelcase */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react';
import Loading from '../Common/Loading';

const SliceServices = () => {
  const [servicesEntry] = usePrismicDocumentByUID('service', 'services');
  if (servicesEntry === undefined) return (<Loading />);

  return (
    <div className="slice-services">
      <Container>
        {servicesEntry.data.services.map((service) => (
          <Row key={service.service} className="service-row">
            <Col xl={4} lg={4}>
              <div className="service-icon">
                <img src={service.icon_k.url} alt="icon" />
              </div>
            </Col>
            <Col xl={4} lg={8}>
              <div className="service-description">
                <h2>{service.service}</h2>
                <PrismicRichText field={service.description} />
              </div>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
};

SliceServices.propTypes = {
  entry: PropTypes.shape({
    data: PropTypes.shape({
      main_title: PropTypes.arrayOf(PropTypes.object),
      sub_title: PropTypes.arrayOf(PropTypes.object),
      intro: PropTypes.arrayOf(PropTypes.object),
      call_to_action: PropTypes.arrayOf(PropTypes.object),
      call_to_action_url: PropTypes.string,
      appeal: PropTypes.arrayOf(PropTypes.object),
      appeal_call_to_action: PropTypes.arrayOf(PropTypes.object),
      appeal_url: PropTypes.string,
      background: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default SliceServices;
