import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// import { Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
// import ContactForm from './ContactFrom';

const GlobalNav = ({ entry }) => {
  const { logo, logo_dark } = entry.data;
  const [navScrollClass, setNavScrollClass] = useState('');
  const [navThemeClass, setNavThemeClass] = useState('');
  const [show, setShow] = useState(false);

  const history = useHistory();
  history.listen(() => {
    window.scrollTo(0, 0);
    setShow(false);
  });

  const location = useLocation();
  // console.log(location);
  useEffect(() => {
    if (location.pathname === '/') {
      setNavThemeClass('light');
    } else {
      setNavThemeClass('');
    }
  }, [location.pathname]);

  window.onscroll = () => {
    if (window.scrollY > 200) {
      setNavScrollClass('has-background');
    } else {
      setNavScrollClass('');
    }
  };
  const toggleShow = () => {
    setShow(!show);
  };
  // console.log(navThemeClass);
  return (
    <div className={`global-nav ${navThemeClass} ${navScrollClass}`}>
      <div className="mobile-header">
        <Link to="/"><img src={logo_dark.url} alt="logo" /></Link>
        <button type="button" className="as-text" onClick={toggleShow}><i className="fas fa-bars" /></button>
      </div>
      <ul className={`menu-items ${show ? '' : 'hide'}`}>
        <li><Link to="/page/services">Services</Link></li>
        <li><Link to="/page/attorneys">Our Attorneys</Link></li>
        <li>
          <Link to="/">
            <img src={navThemeClass === '' ? logo.url : logo_dark.url} alt="logo" />
          </Link>
        </li>
        <li><Link to="/page/news-updates">News</Link></li>
        <li><Link to="/page/offices">Contact Us / Location</Link></li>
      </ul>
    </div>
  );
};
GlobalNav.propTypes = {
  entry: PropTypes.shape({
    data: PropTypes.shape({
      logo: PropTypes.shape({
        url: PropTypes.string,
      }),
      logo_dark: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }).isRequired,
};

/*
const ContactModal = () => {
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };
  return (
    <div>
      <button type="button" className="as-text" onClick={() => toggleShow()}>Contact Us</button>
      <Modal
        show={show}
        onHide={toggleShow}
        centered
        className="contact-modal"
      >
        <Modal.Header><h3>Send Us A Message</h3></Modal.Header>
        <Modal.Body>
          <div className="phone">
            (212) 686-8886 (Manhattan)
            <br />
            (718) 888-0090 (Flushing)
          </div>
          <ContactForm />
        </Modal.Body>
      </Modal>
    </div>
  );
};
*/

export default GlobalNav;
